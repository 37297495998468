<template>
	<div>
		<div class="container">
			<h1>Upraviť profil</h1>
			<w-update-user-settings class="item"></w-update-user-settings>
			<w-user-photo v-if="FEATURE.userPhoto" class="item"></w-user-photo>
			<w-changepwd class="item"></w-changepwd>
		</div>
	</div>
</template>

<script>
export default {
	layout: 'profile',
	
	components: {
		'w-update-user-settings': () => import('./w-user-settings.vue'),
		'w-user-photo': () => import('./w-user-photo.vue'),
		'w-changepwd': () => import('./w-changepwd.vue')
	}
}
</script>

<style lang="scss">
	.item {
		margin-bottom: 50px;
	}
</style>
